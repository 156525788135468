@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'montserratsemibold';
    src: url('./fonts/montserrat-semibold-webfont.woff2') format('woff2'),
         url('./fonts/montserrat-semibold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'ralewayregular';
    src: url('./fonts/raleway-regular.ttf');
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'montserratsemibold', 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Headings */
.generic-title {
  @apply uppercase;
}

/* Buttons */
.btn-confirm {
  @apply text-white bg-green-600 hover:bg-green-700 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm py-2 px-3 text-center;
}

.btn-default {
  @apply text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm py-2 px-3 text-center;
}

.btn-edit {
  @apply text-gray-800 bg-yellow-400 hover:bg-yellow-500 focus:ring-4 focus:ring-yellow-300 font-medium rounded-lg text-sm py-2 px-3 text-center;
}

.btn-edit-disabled {
  @apply text-yellow-400 border border-yellow-400 font-medium rounded-lg text-sm py-2 px-3 text-center cursor-not-allowed;
}

.btn-edit-outline {
  @apply text-yellow-400 hover:text-white border border-yellow-400 hover:bg-yellow-500 focus:ring-4 focus:ring-yellow-300 font-medium rounded-lg text-sm px-3 py-2 text-center;
}

.btn-delete {
  @apply text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm py-2 px-3 text-center;
}

.btn-delete-disabled {
  @apply text-red-700 border border-red-700 font-medium rounded-lg text-sm py-2 px-3 text-center cursor-not-allowed;
}

/* Tables */
.table-th {
  @apply text-xs font-medium px-6 py-3 text-left uppercase tracking-wider;
}

.table-td {
  @apply text-sm px-6 py-4 whitespace-nowrap;
}

/* Forms */
.form-input {
  @apply text-gray-900 bg-gray-50 rounded-lg border border-gray-300 sm:text-xs focus:ring-blue-500 focus:border-blue-500;
}

.form-input-success {
  @apply bg-green-50 border border-green-500 text-green-900 placeholder-green-700 text-sm rounded-lg focus:ring-green-500 focus:border-green-500;
}

.form-input-danger {
  @apply bg-red-50 border border-red-500 text-red-900 placeholder-red-700 text-sm rounded-lg focus:ring-red-500 focus:border-red-500;
}

.form-input-disabled {
  @apply bg-gray-200 border border-gray-300 sm:text-xs text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 cursor-not-allowed;
}

.form-select {
  @apply bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg sm:text-xs focus:ring-blue-500 focus:border-blue-500;
}

.form-label {
  @apply block mb-2 text-sm font-medium text-gray-900;
}

/* Alerts */
.danger-alert {
  @apply p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg;
}

.success-alert {
  @apply p-4 mb-4 text-sm text-green-700 bg-green-100 rounded-lg;
}

/* Text */
.penalty {
  @apply text-red-600;
}

.bonus {
  @apply text-green-500;
}

.value {
  @apply text-yellow-400;
}
